<template>
    <div class="swiper-container">
        <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in list" :key="index">
                <img class="swiper-slide-image" :src="item.image">
            </div>
        </div>
    </div>
</template>
<script>
// import Swiper from 'swiper'

export default {
    data() {
        return {}
    },
    props: {
        list: {
            type: Array,
            default: []
        },
        loop: {
            type: Boolean,
            default: true
        },
        autoplay: {
            type: Boolean,
            default: true
        },
        speed: {
            type: Number,
            default: 1000
        },
        direction: {
            type: String,
            default: 'horizontal'
        },
    },
    mounted() {
        new Swiper('.swiper-container', {
            direction: this.direction, // 垂直切换选项
            loop: this.loop, // 循环模式选项
            autoplay: this.autoplay,
            speed: this.speed
        })
    },
}
</script>
<style lang="less" scoped>
.swiper-container {
    height: calc(100vh - 71px);
}

.swiper-slide-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width:750px) {
    .swiper-container {
        height: calc(100vh);
    }
}
</style>