<template>
  <div class="Recruit">
    <div class="header relative">
      <!-- 轮播图 -->
      <van-swipe :autoplay="3000" :show-indicators="false">
        <van-swipe-item v-for="(item, index) in lunboList" :key="index">
          <img class="van-swipe-item-img" v-lazy="item.image" />
        </van-swipe-item>
      </van-swipe>
      <!-- 菜单栏 -->
      <div class="header-menu flex flex-cos align-center">
        <div class="menu-title b600 f-color4">Join Us</div>
        <ul class="menu-options flex">
          <li
            class="menu-circle relative pointer"
            v-for="item in cateList"
            :key="item.id"
            @click="cateId = item.id"
          >
            <span class="menu-circle-title absolute f-color1 b600 text-align">{{
              item.name
            }}</span>
            <img class="menu-down-icon absolute" src="../assets/icon12.png" />
            <div
              class="circle-child-item absolute"
              v-if="item.child && item.child.length"
            >
              <div
                class="child-item-text f-color1 oneline text-align"
                v-for="it in item.child"
                :key="it.id"
                @click="lookJob(it)"
              >
                {{ it.name }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="Recruit-box w1330">
      <div class="Recruit-title text-align b800">BENEFITS AND BONOS</div>
      <div class="Recruit-content">
        <div class="Recruit-item">
          <div class="Recruit-item-title f-color3 b800">WAGE DISTRIBUTION</div>
          <div class="Recruit-item-intro f-color2 f26">salary plus bonus</div>
          <ul class="Recruit-item-child flex flex-wrap">
            <li
              class="item-child-box flex flex-cos align-center"
              v-for="item in salary"
              :key="item.id"
            >
              <div class="child-box-cover flex align-center justify-center">
                <img :src="item.image" />
              </div>
              <span
                class="child-box-title text-align"
                v-html="item.detail"
              ></span>
            </li>
          </ul>
        </div>
        <div class="Recruit-item">
          <div class="Recruit-item-title f-color3 b800">WELFARE</div>
          <div class="Recruit-item-intro f-color2 f26">all for employees</div>
          <ul class="Recruit-item-child flex flex-wrap">
            <li
              class="item-child-box flex flex-cos align-center"
              v-for="item in welfare"
              :key="item.id"
            >
              <div class="child-box-cover flex align-center justify-center">
                <img :src="item.image" />
              </div>
              <span
                class="child-box-title text-align"
                v-html="item.detail"
              ></span>
            </li>
          </ul>
        </div>
      </div>
      <img class="bot-img" :src="require('@/assets/mans.webp')" />
    </div>

    <!-- 详情 -->
    <div
      class="pop-box flex align-center justify-center"
      v-show="show"
      @touchmove.stop.prevent
      @click="show = false"
    >
      <div class="detail" @click.stop>
        <div class="job-pubtime f26 f-color5">
          发布时间：{{ $api.timeForMat(jobItem.createtime) }}
        </div>
        <div class="job-title flex align-center justify-between">
          <div class="job-name oneline f-color3 f32">{{ jobItem.name }}</div>
          <a
            class="apply-btn f28 f-color1 text-align"
            :href="jobItem.url"
            target="block"
            >申请职位</a
          >
        </div>
        <div class="job-tag f26 f-color5">{{ jobItem.post_postition }}</div>
        <div class="job-address f26 f-color5">{{ jobItem.address }}</div>
        <div class="job-discription f28 f-color3">职位描述</div>
        <div class="job-ask" v-html="jobItem.detail"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import mSwiper from "../components/mySwiper.vue";

export default {
  name: "Home",
  data() {
    return {
      lunboList: [],
      cateList: [],
      salary: [],
      welfare: [],
      jobItem: {},
      show: false,
    };
  },
  components: {
    mSwiper,
  },
  created() {
    this.setNavbarCurrent(this.$route.path);
    this.getLunbo();
    this.getWafeList();
    this.getCateList();
  },
  methods: {
    ...mapActions(["setNavbarCurrent"]),
    //获取背景轮播
    async getLunbo() {
      // var res = await this.$api.Get('index/advertisement?position=recrult&page=1&limit=99999')
      // this.lunboList = res.data
      this.lunboList = [
        {
          image: "/Recruit/1.webp",
          status_text: "",
          position_text: "",
        },
      ];
    },
    //招聘分类
    async getCateList() {
      var res = await this.$api.Get("index/recruitCategory");
      this.cateList = res.data;
      this.cateList.forEach((item) => {
        this.getJobList(item);
      });
    },
    //职位列表
    async getJobList(item) {
      var res = await this.$api.Get(
        `index/recruit?recruit_category_id=${item.id}&page=1&limit=99999`
      );
      item.child = res.data;
      this.$forceUpdate();
    },
    //福利待遇
    async getWafeList() {
      var res = await this.$api.Get("index/recruitDetail");
      this.salary = res.data.salary;
      this.welfare = res.data.welfare;
    },
    lookJob(it) {
      this.jobItem = it;
      this.show = true;
    },
  },
};
</script>
<style lang="less" scoped>
.van-swipe-item-img {
  display: block;
  width: 100%;
  height: calc(100vh - 71px);
  object-fit: cover;
}

.header-menu {
  top: 0;
  left: 50%;
  // transform: translateX(-50%);
  z-index: 99;
}

.menu-title {
  margin: 10vh 0 10vh;
  font-size: 100px;
  font-style: italic;
  white-space: nowrap;
  color: #f96f2f;
  font-family: Saira;
  opacity: 0.8;
}

.menu-circle {
  margin: 0 120px 0 0;
  width: 230px;
  height: 230px;
  background-color: #7d7d7d;
  border-radius: 50%;
  transition: 0.3s ease;
}

.menu-circle:nth-of-type(4n) {
  margin: 0;
}

.menu-circle::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  border: solid 1px #ffffff;
  border-radius: 50%;
}

.menu-circle-title {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 28px;
  font-family: Saira;
}

.menu-down-icon {
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  width: 36px;
  height: 21px;
  transition: 0.3s ease;
}

.circle-child-item {
  top: 260px;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
  height: 0;
  opacity: 0;
  background-color: #7d7d7d;
  border-radius: 15px;
  overflow: scroll;
  transition: 0.3s 0.1s ease;
}

.child-item-text {
  padding: 15px;
  font-size: 20px;
}

.menu-circle:hover {
  background-color: #535252;
}

.menu-circle:hover .menu-down-icon {
  top: 235px;
}

.menu-circle:hover .circle-child-item {
  height: 250px;
  opacity: 1;
}

.child-item-text:hover {
  background-color: #fff;
  color: #000;
}

@keyframes aos_load {
  0% {
    background: rgba(0, 0, 0, 0);
  }

  100% {
    background: rgba(0, 0, 0, 0.7);
  }
}

@keyframes aos_scale {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

.pop-box {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  animation: aos_load 1s ease;
}

.detail {
  padding: 50px;
  width: 60%;
  height: 80vh;
  background-color: #ffffff;
  box-shadow: 0px 1px 32px 0px rgba(6, 0, 1, 0.08);
  border-radius: 15px;
  overflow-y: scroll;
  animation: aos_scale 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.apply-btn {
  width: 197px;
  height: 90px;
  background-color: #f96f2f;
  border-radius: 10px;
  line-height: 90px;
}

.apply-btn:hover {
  background-color: #f55810;
}

.job-title {
  margin: 30px 0 20px;
}

.job-address {
  margin-top: 30px;
}

.job-discription {
  margin: 60px 0 30px;
}

.job-ask {
  margin-top: 30px;
}

.Recruit-title {
  padding: 90px 0;
  line-height: 1;
  font-size: 80px;
  font-family: Saira;
  color: #f96f2f;
}

.Recruit-content {
  padding-bottom: 100px;
}

.Recruit-item:nth-of-type(n + 2) {
  margin-top: 100px;
}

.Recruit-item-title {
  font-size: 32px;
}

.Recruit-item-intro {
  margin-top: 20px;
}

.item-child-box {
  margin: 80px 150px 0 0;
  width: 220px;
}

.item-child-box:nth-of-type(4n) {
  margin: 80px 0 0 0;
}

.child-box-cover {
  width: 100%;
  height: 220px;
}

.child-box-cover img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.child-box-title {
  width: 120%;
  margin-top: 30px;
  font-size: 34px;
  color: #454545;
}

.bot-img {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}
@media screen and (max-width: 750px) {
  .van-swipe-item-img {
    height: calc(100vh);
  }

  .header-menu {
    top: 15vh;
  }

  .menu-title {
    display: none;
  }

  .menu-options {
    margin-top: 20px;
    width: 280px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .menu-circle {
    margin: 0 0px 20px 0;
    width: 120px;
    height: 120px;
    cursor: none;
  }

  .menu-circle::after {
    width: 100px;
    height: 100px;
  }

  .menu-circle:nth-of-type(2n) {
    margin: 0;
  }

  .menu-circle-title {
    font-size: 14px;
  }

  .menu-down-icon {
    top: 45px;
    width: 18px;
    height: 11px;
  }

  .circle-child-item {
    position: relative;
    z-index: 99;
    top: 140px;
    width: 146px;
    border-radius: 8px;
    max-height: 150px;
  }

  .child-item-text {
    padding: 10px;
    font-size: 12px;
  }

  .menu-circle:hover .menu-down-icon {
    top: 124px;
  }

  .menu-circle:hover .circle-child-item {
    height: 150px;
  }

  .detail {
    padding: 20px;
    width: 300px;
  }

  .job-title {
    margin: 20px 0 10px;
  }

  .job-pubtime,
  .job-tag,
  .job-address,
  .job-name,
  .job-ask {
    font-size: 14px;
  }

  .apply-btn {
    width: 70px;
    height: 30px;
    line-height: 30px;
    font-size: 10px;
    border-radius: 6px;
  }

  .job-address {
    margin-top: 10px;
  }

  .job-discription {
    margin: 30px 0 10px;
    font-size: 20px;
  }

  .job-ask {
    margin-top: 10px;
  }

  .Recruit-box {
    width: 300px;
  }

  .Recruit-title {
    padding: 40px 0 30px;
    font-size: 20px;
  }

  .Recruit-content {
    padding-bottom: 20px;
  }

  .Recruit-item:nth-of-type(n + 2) {
    margin-top: 30px;
  }

  .Recruit-item-title,
  .Recruit-item-intro {
    font-size: 11px;
    text-align: center;
  }

  .Recruit-item-intro {
    margin-top: 10px;
  }

  .item-child-box {
    margin: 20px 50px 0 0;
    width: 66px;
  }

  .item-child-box:nth-of-type(3n) {
    margin: 20px 0 0 0;
  }

  .item-child-box:nth-of-type(4n) {
    margin: 20px 50px 0 0;
  }

  .child-box-cover {
    height: 64px;
  }

  .child-box-title {
    margin-top: 10px;
    font-size: 11px;
  }
}
</style>
